"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainManagementMessage = exports.DomainCreatedMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("./message-source");
const tracing_1 = require("./tracing");
const DomainCreatedDetailType = 'Domain Created';
const DomainCreatedDetail = typebox_1.Type.Object({
    domainName: typebox_1.Type.String({
        title: 'Domain of Site',
        description: 'The domain name of the domain created',
    }),
    siteId: typebox_1.Type.String({
        title: 'Site Id',
        description: 'Identifier of the Site record for which a domain was created',
        format: 'uuid',
    }),
}, {
    title: DomainCreatedDetailType,
    description: 'Expected shape of the Detail field of message emitted when a new Domain is created',
});
exports.DomainCreatedMessage = typebox_1.Type.Object({
    detail: DomainCreatedDetail,
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal(DomainCreatedDetailType),
});
exports.DomainManagementMessage = typebox_1.Type.Union([exports.DomainCreatedMessage]);
