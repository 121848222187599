"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowGuestMessage = exports.ShowGuestAddedMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("./message-source");
const tracing_1 = require("./tracing");
const ShowGuestMessageProperties = typebox_1.Type.Object({
    attendeeId: typebox_1.Type.String({
        title: 'Attendee Id',
        description: 'Identifier of the Attendee record of the added guest',
        format: 'uuid',
    }),
    guestId: typebox_1.Type.String({
        title: 'Guest Id',
        description: 'Identifier of the Guest record',
        format: 'uuid',
    }),
    showId: typebox_1.Type.String({
        title: 'Show Id',
        description: 'Identifier of the Show record to which a guest was added',
        format: 'uuid',
    }),
}, {
    title: 'Show Guest',
    description: 'Expected shape of the Detail field of message emitted when Guest is added, deleted or modified in a Show',
});
exports.ShowGuestAddedMessage = typebox_1.Type.Object({
    detail: ShowGuestMessageProperties,
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('Show Guest Added'),
});
const ShowGuestDeletedMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        guests: typebox_1.Type.Array(ShowGuestMessageProperties),
    }),
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('Show Guests Deleted'),
});
const ShowGuestUpdatedMessage = typebox_1.Type.Object({
    detail: ShowGuestMessageProperties,
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('Show Guest Updated'),
});
exports.ShowGuestMessage = typebox_1.Type.Union([
    exports.ShowGuestAddedMessage,
    ShowGuestDeletedMessage,
    ShowGuestUpdatedMessage,
]);
